import novelaTheme from '@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui';

export default {
  ...novelaTheme,
  colors: {
    ...novelaTheme.colors,
    primary: '#000',
    secondary: '#73737D',
    accent: '#60b6f2',
    grey: '#393940',
    background: '#fff',
    progress: "#5fb4f0",
    modes: {
      dark: {
        grey: "#b3b3b3",
        primary: "#fff",
        secondary: "#305b79",
        accent: "#8ecaf4",
        background: "#272B30",
        gradient: "linear-gradient(180deg, #272B30 0%, #21252B 100%)",
        articleText: "#fff",
        track: "rgba(255, 255, 255, 0.3)",
        progress: "#5fb4f0",
        card: "#1D2128",
      },
    },
  },
};
